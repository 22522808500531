<template>
    <section class="promo-section ptb-100 position-relative overflow-hidden" v-bind:class="{ 'gray-light-bg' : isGray }" style="margin-bottom: -0px;">
        <div class="effect-2">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 463.6 616" style="enable-background:new 0 0 463.6 616;" xml:space="preserve" class="injected-svg svg_img dark-color">
                    <path fill="#f5f5f5" d="M148.4,608.3C25.7,572.5-3.5,442.2,0.3,375.8s24.8-117,124.8-166.5s125.7-77.4,165-129.6 c43.2-57.4,96.5-94.4,127.9-73c63,43,53.9,280,14,358s-68.9,75.5-98.9,118.7S271,644,148.4,608.3z"></path>
                </svg> -->
        </div>
        <div class="container" style="position: relative;">
            <img 
                class="backgroundImgTwo"
                style="position: absolute;top: 100px; right: -50px; width: 280px; opacity: .8;"
                :src="require('@/assets/illustrations/backgroundGridTwo.svg')" 
                alt=""
            >
            <div class="row">
                <div class="col-lg-7 col-md-10">
                    <div class="section-heading">
                        <h2>Storiumi e-pood kohandub vastavalt Sinu äri vajadustele</h2>
                        <p>Jätkusuutlik koostöö kus meie hoolitseme tehnilise poole eest, et teie saaksite keskenduda kõige olulisemale, müümisele. </p>
                    </div>
                </div>
            </div>

            <div class="row" style="position: relative;z-index: 1;">
                <div class="col-md-6 col-lg-3">
                    <div class="card border-0 single-promo-card single-promo-hover-2 p-2 mt-4">
                        <div class="card-body">
                            <div class="pb-2">
                                <span class="fas fa-seedling icon-size-md color-secondary"></span>
                            </div>
                            <div class="pt-2 pb-3">
                                <h5>Vähenõudlik</h5>
                                <p class="mb-0">Intuitiivne halduskeskkond tagab, et tellimuste täitmine ja toodete haldamine on lihtne ja vähe aeganõudev.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3">
                    <div class="card border-0 single-promo-card single-promo-hover-2 p-2 mt-4">
                        <div class="card-body">
                            <div class="pb-2">
                                <span class="fas fa-shopping-basket icon-size-md color-secondary"></span>
                            </div>
                            <div class="pt-2 pb-3">
                                <h5>Mugav</h5>
                                <p class="mb-0">Kiire tellimisprotsess tagab head tingimused, et klient jõuaks toote sirvimisest ostuni.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3">
                    <div class="card border-0 single-promo-card single-promo-hover-2 p-2 mt-4">
                        <div class="card-body">
                            <div class="pb-2">
                                <span class="fas fa-lock icon-size-md color-secondary"></span>
                            </div>
                            <div class="pt-2 pb-3">
                                <h5>Turvaline</h5>
                                <p class="mb-0">Maksesüsteemidest kuni isikuandmeteni, meie tagame Sinu poe ja kliendi turvalisuse.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3">
                    <div class="card border-0 single-promo-card single-promo-hover-2 p-2 mt-4">
                        <div class="card-body">
                            <div class="pb-2">
                                <span class="fas fa-chart-line icon-size-md color-secondary"></span>
                            </div>
                            <div class="pt-2 pb-3">
                                <h5>Progressiivne</h5>
                                <p class="mb-0">Storium areneb koos Sinuga. Loome üha paremaid tingimusi, et sinu müüginumbreid kasvatada.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <svg width="176" height="247" viewBox="0 0 176 247" style="margin-left: 37px; margin-top: -26px; opacity: .5;" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.00049 1C1.00049 1 72.4644 548.105 175 7.18198" stroke="#848484" stroke-dasharray="6 6"/>
            </svg>
            <svg width="176" height="247" viewBox="0 0 176 247" style="margin-left: 113px; margin-top: -26px; opacity: .5;" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.00049 1C1.00049 1 72.4644 548.105 175 7.18198" stroke="#848484" stroke-dasharray="6 6"/>
            </svg>
            <svg width="176" height="247" viewBox="0 0 176 247" style="margin-left: 106px; margin-top: -26px; opacity: .5;" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.00049 1C1.00049 1 72.4644 548.105 175 7.18198" stroke="#848484" stroke-dasharray="6 6"/>
            </svg>
            <svg width="176" height="247" viewBox="0 0 176 247" style="margin-left: 106px; margin-top: -26px; opacity: .5;" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.00049 1C1.00049 1 72.4644 548.105 175 7.18198" stroke="#848484" stroke-dasharray="6 6"/>
            </svg> -->
        </div>
    </section>
</template>


<script>
    export default {
        name: 'Promo',
        props: {
            isGray: {
                type: Boolean,
                default: false
            }
        }
    }
</script>
<style>
.backgroundImgTwo {
    position: absolute;
    right: 0px;
    width: 100%;
    opacity: 1.20;
}
@media (max-width: 780px) {
    .backgroundImgTwo {
        display: none;
    }
}
</style>