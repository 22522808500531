<template>
  <!--hero section start-->
  <section id="bannerHome" class="position-relative overflow-hidden hero-section-3 ptb-100">
    <!--animated circle shape start-->
    <div class="animated-shape-wrap">
      <div class="animated-shape-item"></div>
      <div class="animated-shape-item"></div>
      <div class="animated-shape-item" style="opacity: 0;"></div>
      <div class="animated-shape-item"></div>
      <div class="animated-shape-item"></div>
    </div>
    <!--animated circle shape end-->
    <img src="../../assets/img/combined-shape.svg"  alt="s" class="shape-img-2">
    <img src="../../assets/img/shape-1.svg" alt="group shape" width="100" id="moveIllustration" class="img-fluid group-shape-1">
    <img src="../../assets/img/shape-2.svg" alt="group shape" width="60" class="img-fluid group-shape-2">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-md-6 col-lg-6">
          <div class="hero-content-left pt-5">
            <h1 class="font-weight-bold">Lollilt lihtne e-poe lahendus</h1>
            <p class="lead">Storium on moderne e-poe lahendus, mis võimaldab kasutajasõbralikult igal inimesel, olenemata teadmistest, müüa ja hallata enda tooteid internetis.</p>
            <div class="action-btns mt-4">
              <ul class="list-inline">
                <li class="list-inline-item">
                  <a href="#" class="d-flex align-items-center app-download-btn btn btn-outline-brand-02 btn-rounded">
                    <span class="pi pi-chart-line icon-size-sm mr-3"></span>
                    <div class="download-text text-left">
                      <small>Vaata e-poe demo</small>
                      <h5 class="mb-0">Halduskeskkond</h5>
                    </div>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="#" class="d-flex align-items-center app-download-btn btn btn-outline-brand-02 btn-rounded">
                    <span class="pi pi-shop icon-size-sm mr-3"></span>
                    <div class="download-text text-left">
                      <small>Vaata e-poe demo</small>
                      <h5 class="mb-0">Kliendi vaade</h5>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div class="img-wrap">
            <img src="../../assets/img/hero-image.svg" alt="hero" class="img-fluid">
          </div>
        </div>
      </div>
    </div>

    <!--shape image start-->
    <img src="../../assets/img/hero-bg-shape-4.svg" class="shape-image" alt="shape image">
    <!--shape image end-->
  </section>
</template>

<script>
export default {
  name: "Banner",
};
</script>

<!--<style lang="scss">-->
  <!--.hero-bottom-shape-bg {-->
    <!--background: url('../../assets/img/hero-bottom-shape-2.svg')no-repeat bottom center-->
  <!--}-->
<!--</style>-->

<style>
@media (max-width: 767px) {
    .group-shape-1 {
        position: absolute;
        left: 80px;
        top: -65px;
    }
}
</style>